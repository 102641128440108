import React, { Component } from "react";
import ReactDOM from 'react-dom';
import {
  Button,
  Card,
  Spin,
  Divider,
  Icon,
  Tooltip,
  Modal,
  Col,
  Row,
  Tag,
  Statistic,
  Popover,
  Slider,
  Table,
  Skeleton
} from "antd";
import moment from "moment";
import api from "../services/api";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import cancelXhrAdapter from "axios-cancel";
import { Cancellation } from "axios-cancel/cancel";
import { getRequestFilters } from "../services/filters";
import iconDose from "../assets/dose1.svg";
import iconSurgery from "../assets/surgery.svg";
import iconStartAnesthesia from "../assets/anesthesia.svg";
import iconRepique from "../assets/repique1.svg";
import storeAntibiotcs from "../services/antibiotics.json";
import {getOnlyChar, DiffSecondsToMinutes} from "../Utils";

HighchartsMore(Highcharts);

let history_rangePreInterval = [];

class ChartPharmaAnaphylactic extends Component {
  

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modalAntibioticsVisible: false,
      modalProceduresVisible: false,
      loadingModal: false,
      rangePreInterval: [30, 60],
      kpiFirstCorrect: null,
      kpiRepiquesCorrect: null,
      countProcedures: null,
      countRepiques: null,
      categories: [],
      maxInterval: null,
      data: [],
      tableProcedures: {},
      series: [
        {
          showInLegend: false,
          name: " ",
          data: []
        }
      ],
      period_ini: "00:30"
    };
    // This binding is necessary to make `this` work in the callback
    this.showModalProcedures = this.showModalProcedures.bind(this);
  }



  showModalAntibiotics = () => {

    this.setState({modalAntibioticsVisible: true});

  };

  showModalProcedures = (antibiotic) => {

    let procedure = {name: '', list: []};
    
    if(this.state.data.length){
      this.state.data.forEach(function(p_value, index) {
        if(p_value.name.toLowerCase() === antibiotic.toLowerCase()){
          procedure.name = p_value.name;
          procedure.list = p_value.procedures;
        }
      });
    }
    
    this.setState({tableProcedures: procedure});

  };

  handleAntibioticsCancel= e => {
    this.setState({
      modalAntibioticsVisible: false
    });
  };

  handleProceduresCancel= e => {
    this.setState({
      tableProcedures: [],
      modalProceduresVisible: false
    });
  };

  isPointZero = number => {
    if (number < 0) return 0;

    return number;
  };

  updateTimeFirstDose = e => {
    if (!e) {
      if (history_rangePreInterval !== this.state.rangePreInterval) {
        this.loadData();
      }
    } else {
      history_rangePreInterval = this.state.rangePreInterval;
    }
  };

  loadData = () => {
    this.setState({ loading: true });
    let objFilters = getRequestFilters();

    this.cancellation = new Cancellation();
    this._isMounted = true;
    api
      .post("dashboard/antibiotics/usage/", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation
      })
      .then(res => {
        const self = this;
        if (this._isMounted) {
          // convert array in string html tootip
          const newCategories = [];
          const newData = [];
          let arrayStartAnesthesia = [];
          let arrayFirstToStartSurgery = [];
          let arrayPeal = [];
          let arrayStartSurgery = [];
          let arrayEndPoint = [];
          let arrayRanges = [];
          let arrayRange = [];
          let arrayRangePeal = [];
          let arrayRangeAnesthesia = [];
          let maxInterval = 0;

          let countProcedures = 0; // Total Procedures
          let countRepiques = 0; // Total Procedures
          let countFirstCorrect = 0; // Total Procedures - first dose interval correct
          let countIntervalDoseCorrect = 0; // Total Procedures - repique dose interval correct

          res.data.forEach(function(value, index) {
            newCategories.push(value.name);
            const intervalAntibiotic = storeAntibiotcs.filter(function(key) {
              return key.antibiotic.toLowerCase() === value.name.toLowerCase();
            })[0];
            //console.log(value.name.toLowerCase(),intervalAntibiotic);

            maxInterval = Math.max.apply(
              Math,
              res.data.map(item => {
                return parseInt(item.average.interval_doses * 1000) + 1800000;
              })
            ); // MAX INTERVAL CHART

            arrayFirstToStartSurgery.push({
              y: value.average.start_anesthesia_to_first ? parseInt(value.average.start_anesthesia_to_first * 1000): 0,
              first_to_start_surgery: parseInt(value.average.first_to_start_surgery * 1000)}
            );

            //arrayFirstToStartSurgery.push(value.average.start_anesthesia_to_first ? parseInt(value.average.start_anesthesia_to_first * 1000): 0);

            arrayPeal.push(
              value.average.interval_doses
                ? parseInt(value.average.interval_doses * 1000)
                : null
            );

            const calc =
              parseInt(value.average.start_anesthesia_to_first * 1000) +
              1800000;
            arrayRange.push([
              parseInt(value.average.start_anesthesia_to_first * 1000),
              calc
            ]);

            const calcRangePeal =
              parseInt(value.average.start_anesthesia_to_first * 1000) +
              7200000;

            arrayRangePeal.push([
              parseInt(value.average.start_anesthesia_to_first * 1000),
              calcRangePeal
            ]);

            arrayStartSurgery.push(
              parseInt(value.average.start_anesthetia_surgery * 1000)
            );

            arrayStartAnesthesia.push(0);
            arrayEndPoint.push(maxInterval);
            arrayRangeAnesthesia.push([0, maxInterval]);

            const lowPreInterval =
              parseInt(value.average.start_anesthetia_surgery * 1000) -
              self.state.rangePreInterval[0] * 60000;
            const highPreInterval =
              lowPreInterval - self.state.rangePreInterval[1] * 60000;

            arrayRanges.push(
              {
                x: index,
                color: "rgba(98, 209, 113, 0.2)",
                low: self.isPointZero(lowPreInterval),
                high: self.isPointZero(highPreInterval),
                name: "Intervalo de preconização cirúrgico",
                zIndex: 0
              },
              {
                x: index,
                color: "rgba(240, 115, 150, 0.2)",
                low: parseInt(value.average.start_anesthetia_surgery * 1000),
                high: self.isPointZero(
                  parseInt(value.average.start_anesthetia_surgery * 1000) -
                    parseInt(self.state.rangePreInterval[0] * 60000)
                ),
                name: "Zona de Insegurança",
                zIndex: 0
              },
              {
                x: index,
                color: "rgba(54, 162, 235, 0.2)",
                low: parseInt(
                  self.isPointZero(lowPreInterval) +
                    (parseInt(intervalAntibiotic.interval_seconds * 1000) -
                      600000)
                ),
                high: parseInt(
                  self.isPointZero(lowPreInterval) +
                    (parseInt(intervalAntibiotic.interval_seconds * 1000) +
                      600000)
                ),
                name: "Intervalo de reposição de doses",
                zIndex: 0
              },
              /* {
                x: index,
                color: "rgb(98, 209, 113)",
                low: parseInt(lowPreInterval),
                high: parseInt(lowPreInterval + (7200000 - 600000)),
                name: "Linha de ",
                pointWidth: 2,
                borderColor: "rgb(201, 203, 207, 0.5)"
              },*/
              {
                x: index,
                color: "#cccccc78",
                low: 0,
                high: 86340000,
                name: "Tempo de Anestesia",
                pointWidth: 2,
                borderColor: "#cccccc78"
              }
            );

            /** ################# CALCULE KPI ################## */

            countProcedures = countProcedures + value.average.count.procedures;
            countRepiques =
              countRepiques +
              value.average.count.procedures_with_multiple_doses;

            value.procedures.forEach(function(p_value, index) {
              p_value.data.forEach(function(p_data, index) {
                if (p_data.diffs_doses.first_to_start_surgery > 0) {
                  const calcIntervalInit = self.state.rangePreInterval[0] * 60;
                  const calcIntervalFin = self.state.rangePreInterval[1] * 60;

                  // calcule first dose correct
                  if (
                    p_data.diffs_doses.first_to_start_surgery >
                      calcIntervalInit &&
                    p_data.diffs_doses.first_to_start_surgery < calcIntervalFin
                  ) {
                    countFirstCorrect++;
                  }

                  // calcule interval dose correct
                  if (p_data.diffs_doses.interval_doses.length > 0) {
                    let sumMedia = 0;
                    p_data.diffs_doses.interval_doses.forEach(function(
                      p_dose,
                      index
                    ) {
                      sumMedia = sumMedia + p_dose;
                    });

                    const mediaInterval = parseInt(
                      (sumMedia / p_data.diffs_doses.interval_doses.length) *
                        1000
                    );

                    if (intervalAntibiotic) {
                      const calcIntervalDoseInit = parseInt(
                        lowPreInterval +
                          (parseInt(
                            intervalAntibiotic.interval_seconds * 1000
                          ) -
                            600000)
                      ); // milliseconds
                      const calcIntervalDoseFin = parseInt(
                        lowPreInterval +
                          (parseInt(
                            intervalAntibiotic.interval_seconds * 1000
                          ) +
                            600000)
                      ); // milliseconds

                      //console.log(value.name.toLowerCase(), mediaInterval, calcIntervalDoseInit, calcIntervalDoseFin);

                      if (
                        mediaInterval > calcIntervalDoseInit &&
                        mediaInterval < calcIntervalDoseFin
                      ) {
                        countIntervalDoseCorrect++;
                      }
                    }
                  }
                }
              });
            });
          });

          /*  console.log('Procedures',countProcedures);
          console.log('First Correct',countFirstCorrect);
          console.log('Reqiques',parseInt((countIntervalDoseCorrect / countRepiques) * 100));
          console.log('Percent First',parseInt((countFirstCorrect / countProcedures) * 100));          */

          
         // Highcharts.charts[0].redraw();
                   

          this.setState({
            loading: false,
            kpiFirstCorrect: parseInt(
              (countFirstCorrect / countProcedures) * 100
            ),
            kpiRepiquesCorrect: parseInt(
              (countIntervalDoseCorrect / countRepiques) * 100
            ),
            countProcedures: parseInt(countProcedures),
            countRepiques: parseInt(countRepiques),
            data: res.data,
            maxInterval: maxInterval,
            categories: newCategories,
            series: [
              {
                name: "Zona de insegurança",
                color: "rgba(240, 115, 150, 0.2)",
                data: arrayRanges,
                type: "columnrange",
                borderRadius: 5,
                legendIndex: 5,
                showInLegend: false,
                enableMouseTracking: false,
                marker: {
                  enabled: false
                }
              },
              {
                type: "line",
                name: "Reposição de doses",
                color: "#36a2eb",
                data: arrayPeal,
                showInLegend: true,
                legendIndex: 4,
                marker: {
                  symbol: "url(" + iconRepique + ")",
                  width: 22,
                  height: 22
                },
                lineWidth: 0
              },
              {
                type: "line",
                name: "Começo cirúrgico",
                data: arrayStartSurgery,
                showInLegend: true,
                legendIndex: 3,
                marker: {
                  symbol: "url(" + iconSurgery + ")",
                  width: 22,
                  height: 22
                },
                lineWidth: 0
              },
              {
                type: "line",
                name: "Dose primária",
                data: arrayFirstToStartSurgery,
                showInLegend: true,
                legendIndex: 2,
                marker: {
                  symbol: "url(" + iconDose + ")",
                  width: 22,
                  height: 22
                },
                lineWidth: 0
              },
              {
                type: "line",
                name: "Começo anestésico",
                data: arrayStartAnesthesia,
                showInLegend: true,
                enableMouseTracking: false,
                legendIndex: 1,
                marker: {
                  symbol: "url(" + iconStartAnesthesia + ")",
                  width: 22,
                  height: 22
                },
                lineWidth: 0
              }
            ]
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };



  componentWillReceiveProps(nextProps) {
    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  }

  componentDidMount() {
    if (this.props.lastfilter) {
      this.loadData();
    }
  }


  componentWillUnmount() {
    // cancel request case when pending ...
    if (this.cancellation) {
      this.cancellation.cancel();
      this._isMounted = false;
    }
  }

  resizeHeightChart = () => {
    let heightDevice = 0;

  /* if (window.innerHeight < 798) {
      heightDevice = (9 / 16) * 100 + "%";
    } else if (window.innerHeight > 798 && window.innerHeight < 898) {
      heightDevice = (8 / 16) * 100 + "%";
    } else if (window.innerHeight >= 898) {
      heightDevice = (7 / 16) * 100 + "%";
    }*/

    const x = this.state.data.length;
    switch (true) {
      case (x < 5):
          heightDevice = 400;
          break;
      case (x < 10):
          heightDevice = 500;
          break;
      case (x < 15):
          heightDevice = 600;
          break;
      case (x < 20):
          heightDevice = 700;
          break;
      default:
          heightDevice = 800;
          break;
  }

    return heightDevice;
  };


  
  
  render() {

    var self = this;

    let eventObj = {
      handleEvent(e) {
        //e.stopPropagation();
       // self.showModalProcedures(e.target.innerText);
      }
    }

    let options = {
      chart: {
        zoomType: "y",
        inverted: true,
        height: this.resizeHeightChart(),
        //height: (9 / 16 * 100) + '%',
        //height: parseInt(window.innerHeight - parseInt(window.innerHeight * 40 / 100))
        //height: '40%'
        events: {
          /*load: function () {

           setTimeout(function () {
              const classname = document.getElementsByClassName("title-category");
              Array.from(classname).forEach(function(element) {
               // element.addEventListener('click', eventObj);
               //element.addEventListener("click",self.teste.bind(this))
              }); 
            }.bind(this), 5000)

            
          }*/
    },
      },
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      xAxis: {
        categories: this.state.categories,
        labels: {
          formatter: function() {
            return '<span class="title-category">' + this.value + "</span>";
          },
          useHTML: true
        }
        /* crosshair: {
          enabled: true
        }*/
      },
      yAxis: {
        crosshair: {
          enabled: true
        },
        min: 0,
        tickInterval: 900000,
        max: this.state.maxInterval,
        title: {
          text: "Tempo médio (min)"
        },
        type: "datetime", //y-axis will be in milliseconds
        //minRange: this.state.maxInterval,
        dateTimeLabelFormats: {
          //force all formats to be hour:minute:second
          second: "%Hh %Mmin",
          minute: "%Hh %Mmin",
          hour: "%Hh %Mmin",
          day: "%Hh %Mmin",
          week: "%Hh %Mmin",
          month: "%Hh %Mmin",
          year: "%Hh %Mmin"
        },
        scrollbar: {
          enabled: true
        }
      },
      series: this.state.series,
      plotOptions: {
        series: {
          //stacking: "normal",
          states: {
            hover: {
              lineWidthPlus: 0
            }
          }
        }
      },
      tooltip: {
        formatter: function() {
          
          let legendStartToSurgery = "";

          if(this.point.first_to_start_surgery){
            if(this.point.first_to_start_surgery > 0){
              legendStartToSurgery = "<br>-<br>Intervalo entre começo cirúrgico: " + Highcharts.dateFormat('%Hh %M',this.point.first_to_start_surgery) + 'min (tempo médio)';
            }
            else{
              legendStartToSurgery = "<br>-<br><span style='color: #ffffb3'>Intervalo entre começo cirúrgico:  " + Highcharts.dateFormat('%Hh %M',Math.abs(this.point.first_to_start_surgery)) + 'min (tempo médio) (Posterior) </span>';
            }
          }


          return  '<small style="font-size: 11px">'+this.x+'</small><br>' + this.series.name + ': ' + Highcharts.dateFormat('%Hh %M',this.y) + 'min (tempo médio)' + legendStartToSurgery; 
          
     },
        //pointFormat: "{series.name}: {point.y:%Hh %Mmin} (tempo médio) <br> {point.teste:%Hh %Mmin}",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        borderWidth: 0,
        borderRadius: 5,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0,
          color: "white"
        }
      }
    };

    const content = (
      <div>
        <div>
          <Slider
            onChange={value => this.setState({ rangePreInterval: value })}
            style={{ width: "90%", margin: "14px 10px 10px" }}
            range
            marks={{ 0: "0 min", 30: "30min", 60: "60 min" }}
            max={60}
            value={this.state.rangePreInterval}
            step={5}
          />
        </div>

        <div style={{ paddingTop: "20px" }}>
          <small>
            <b>Padrão:</b> 30 - 60 min. conforme orientação farmacêutica.
          </small>
        </div>
      </div>
    );


    const columnsProcedures= [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: id=> <span>{id}</span>
      },
      {
        title: "Procedimento",
        dataIndex: "name",
        key: "name",
        render: name => <span>{name}</span>
      },
      {
        title: "Anestesista",
        dataIndex: "institution_id",
        key: "institution_id",
        render: anestesista => <span>Anestesista Lorem Ipson</span>
      },
      /*{
        title: "Duração cirúrgica",
        key: "surgery_duration",
        render: (record) => {
          console.log(record);
          
          return (<span>teste</span>)
        }
      }*/
    ];

    const columnsAntibiotics = [
      {
        title: "Antibiótico",
        dataIndex: "antibiotic",
        key: "antibiotic",
        render: antibiotic => <span>{antibiotic}</span>
      },
      {
        title: "Dosagem",
        dataIndex: "dose",
        key: "dose",
        render: dose => <span>{dose}</span>
      },
      {
        title: "Intervalo de reposição",
        dataIndex: "interval",
        key: "interval",
        render: interval => <span>{interval}</span>
      }
    ];

    return (
      <div>
        <Card
          style={{ minHeight: 445, width: "100%" }}
          title={
            <span>
              {"Cenário de antibióticos profiláticos"}
              {""}
              <small>
                <Divider type="vertical" />
                Tempo médio de procedimentos no período por antibiótico
              </small>
            </span>
          }
          extra={
            <small>
              Selecione uma área para zoom <Icon type="zoom-in" />
            </small>
          }
        >
          <Skeleton loading={this.state.loading} active>
            <Row gutter={16}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={14}
                style={{ paddingBottom: 8 }}
              >
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} style={{ paddingBottom: 8 }}>
                  <Card>
                    <Statistic
                      groupSeparator="."
                      decimalSeparator=","
                      title="Procedimentos"
                      prefix={<Icon type="file-add" />}
                      value={this.state.countProcedures}
                      suffix={
                        <small style={{ color: "#7c8595" }}>
                          {" "}
                          ({this.state.countRepiques} repiques)
                        </small>
                      }
                    />
                  </Card>
                </Col>

                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} style={{ paddingBottom: 8 }}>
                  <Card>
                    <Statistic
                      title={
                        <span>
                          <Tooltip
                            placement="top"
                            trigger="hover"
                            title={() => (
                              <span>
                                Taxa de administração do antibioticoprofilaxia
                                no tempo correto de preconização da incisão
                                cirúrgica. <br />
                                <br /> Vários estudos demonstram que quando o
                                antibiótico preventivo é administrado num
                                momento próximo ao início da cirurgia, ele é
                                mais eficaz. <br />
                                <small>
                                  Meta: 90% (baseada no Benchmarking medical
                                  care 2017)
                                </small>
                              </span>
                            )}
                          >
                            Taxa de preconização correta{" "}
                            <Icon type="question-circle" />{" "}
                          </Tooltip>
                        </span>
                      }
                      value={isNaN(this.state.kpiRepiquesCorrect) ? "...-" : this.state.kpiFirstCorrect}
                      valueStyle={
                        isNaN(this.state.kpiRepiquesCorrect) ? 
                        { color: "gray" } :
                        this.state.kpiFirstCorrect > 90
                          ? { color: "#62d171" }
                          : { color: "#cf1322" }
                      }
                      prefix={
                        isNaN(this.state.kpiRepiquesCorrect) ? 
                        ( <span></span>) :
                        this.state.kpiFirstCorrect > 90 ? (
                          <Icon type="arrow-up" />
                        ) : (
                          <Icon type="arrow-down" />
                        )
                      }
                      suffix={
                        isNaN(this.state.kpiRepiquesCorrect) ? 
                        (<span></span>):
                        <span>
                          %{" "}
                          <small style={{ color: "#7c8595" }}>meta (90%)</small>
                        </span>
                      }
                    />
                  </Card>
                </Col>

                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} style={{ paddingBottom: 8 }}>
                  <Card>
                    <Statistic
                      title={
                        <span>
                          <Tooltip
                            placement="top"
                            trigger="hover"
                            title={() => (
                              <span>
                                Taxa de administração do antibioticoprofilaxia
                                no intervalo correto de reposiçaõ de dose.{" "}
                                <br />
                                <br /> Vários estudos demonstram que quando o
                                antibiótico preventivo é administrado no momento
                                correto de reposição de acordo com antibiótico e
                                dosagem, o paciente fica menos vulnerável à
                                possíveis infecções.
                                <br />
                                <small>
                                  Meta: 90% (baseada no Benchmarking medical
                                  care 2017)
                                </small>
                              </span>
                            )}
                          >
                            Taxa de reposição correta{" "}
                            <Icon type="question-circle" />{" "}
                          </Tooltip>
                        </span>
                      }
                      value={isNaN(this.state.kpiRepiquesCorrect)? '...' : this.state.kpiRepiquesCorrect}
                      valueStyle={
                        isNaN(this.state.kpiRepiquesCorrect) ?
                        {color: "gray" } :
                        this.state.kpiRepiquesCorrect > 90
                          ? { color: "#62d171" }
                          : { color: "#cf1322" }
                      }
                      prefix={
                        isNaN(this.state.kpiRepiquesCorrect) ? (
                          <span></span>
                        ) : (
                          this.state.kpiRepiquesCorrect > 90 ? (
                            <Icon type="arrow-up" />
                          ) : (
                            <Icon type="arrow-down" />
                          )
                        )
                      }
                      suffix={
                        isNaN(this.state.kpiRepiquesCorrect) ? (
                          <span></span>
                        ) : (
                        <span>
                          %{" "}
                          <small style={{ color: "#7c8595" }}>meta (90%)</small>
                        </span>)
                      }
                    />
                  </Card>
                </Col>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={10}
                style={{ textAlign: "left", paddingBottom: 8  }}
              >
                  <Card>
                    <div className="ant-statistic">
                      <div className="ant-statistic-title">
                        Intervalo de segurança recomendado de profilaxia
                      </div>
                      <div
                        className="ant-statistic-content"
                        style={{ color: "rgb(207, 19, 34)" }}
                      >
                        <span className="ant-statistic-content-prefix">
                

                        <Tag color="green">
                              <Tooltip
                                placement="top"
                                title={
                                  <span>
                                    Intervalo de segurança preliminar de incisão
                                    cirúrgica
                                    <br />
                                    <small>
                                      Padrão: 30 - 60 min. (de acordo com
                                      recomendações farmacêuticas)
                                    </small>
                                  </span>
                                }
                              >
                                <Icon type="question-circle" /> Preconização de
                                incisão cirúrgica &nbsp;
                              </Tooltip>

                              <Popover
                                onVisibleChange={this.updateTimeFirstDose}
                                content={content}
                                title="Intervalo de preconização cirúrgica"
                                trigger="click"
                                overlayStyle={{
                                  width: "300px"
                                }}
                              >
                                <Button
                                  type="dashed"
                                  shape="round"
                                  size="small"
                                  className="btn-popover-interval"
                                >
                                  {this.state.rangePreInterval[0]} &nbsp;{" - "}
                                  &nbsp; {this.state.rangePreInterval[1]}
                                  &nbsp;min
                                </Button>
                              </Popover>
                            </Tag>
                      
                        <Tag color="geekblue">
                              <Tooltip
                                placement="top"
                                title="Intervalo de segurança para reposição de doses  de acordo com tipo de antibiótico e dosagem"
                              >
                                <Icon type="question-circle" /> Reposição de
                                doses &nbsp;
                              </Tooltip>

                              <Button
                                type="dashed"
                                shape="round"
                                size="small"
                                onClick={this.showModalAntibiotics}
                              >
                                Visualizar tabela
                              </Button>
                            </Tag>
                      
                        </span>
                      </div>
                    </div>
                  </Card>
                </Col>
        
            </Row>
          </Skeleton>
          <Spin className="ant-spin-lg" spinning={this.state.loading}>
            <Row style={{ paddingTop: 20 }}>
              <HighchartsReact highcharts={Highcharts} options={options} />
            </Row>
          </Spin>

          <Modal
            title="Tabela de procedimentos no período"
            key="1"
            visible={this.state.modalProceduresVisible}
            onOk={this.handleProceduresCancel}
            className="container-modal"
            onCancel={this.handleProceduresCancel}
            footer={[
              <Button
                type="primary"
                className="btn-custom-primary"
                key="9"
                onClick={this.handleProceduresCancel}
              >
                OK
              </Button>
            ]}
          >
            <Table
              rowKey="name"
              size="midle"
              columns={columnsProcedures}
              dataSource={this.state.tableProcedures.list}
              pagination={false}
            />
          </Modal>


          <Modal
            title="Tabela de reposição de dosagens"
            key="2"
            visible={this.state.modalAntibioticsVisible}
            onOk={this.handleAntibioticsCancel}
            className="container-modal-med"
            onCancel={this.handleAntibioticsCancel}
            footer={[
              <Button
                type="primary"
                className="btn-custom-primary"
                key="11"
                onClick={this.handleAntibioticsCancel}
              >
                OK
              </Button>
            ]}
          >
            <Table
              rowKey="antibiotic"
              size="midle"
              columns={columnsAntibiotics}
              dataSource={storeAntibiotcs}
              pagination={false}
            />
          </Modal>
        </Card>
      </div>
    );
  }
}

export default ChartPharmaAnaphylactic;
