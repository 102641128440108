import React, { Component } from "react";
import { Card, Spin, Button, Tooltip } from "antd";
import api from "../../services/api";
import cancelXhrAdapter from 'axios-cancel';
import {Cancellation} from 'axios-cancel/cancel';
import { getRequestFilters } from "../../services/filters";

class IndicatorAfterOperative extends Component {
  state = {
    loading: true,
    hour: null,
    value: null
  };

  loadData = () => {
    this.setState({ loading: true, value: null});
    let objFilters = getRequestFilters();

    this.cancellation = new Cancellation();
    this._isMounted = true;
    api
      .post("dashboard/total/trans_without_post/", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation
      })
      .then(res => {

        if (this._isMounted) {
        this.setState({
          loading: false,
          value: res.data.without_percent.toString()
        });
      }

      })
      .catch(error => {
        console.log(error);
      });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  }

  componentDidMount() {
    if (this.props.lastfilter) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // cancel request case when pending ...
    if(this.cancellation) {
      this.cancellation.cancel();
      this._isMounted = false;
    }
  }


  render() {
    return (
      <div>
        <Card className="card-indicator">

          <div className="card-number">
            <span>
              <Spin className="ant-spin-lg" spinning={this.state.loading}>
                &nbsp;
                {this.state.value}
                <small>
                  {this.state.value ? "%" : ""}
                </small>
              </Spin>
            </span>
          </div>
          <div className="card-sub-title">Procedimentos sem visita SRPA</div>
          <div className="card-info">
            <Tooltip
              placement="topLeft"
              title="Indice de trans operatório sem visita posterior"
            >
              <Button shape="circle" icon="info" className="btn-card-details" />
            </Tooltip>
          </div>
        </Card>
      </div>
    );
  }
}

export default IndicatorAfterOperative;
