import React, { Component } from "react";
import { Button, Card, Icon, Spin, Divider, Modal, Table, Tooltip } from "antd";
import moment from "moment";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import api from "../services/api";
import cancelXhrAdapter from "axios-cancel";
import { Cancellation } from "axios-cancel/cancel";
import { getRequestFilters } from "../services/filters";
import {getOnlyChar} from "../Utils";

Highcharts.setOptions({
  lang: {
    loading: "Carregando ...",
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],
    weekdays: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado"
    ],
    shortMonths: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez"
    ],
    exportButtonTitle: "Exportar",
    printButtonTitle: "Imprimir",
    rangeSelectorFrom: "De",
    rangeSelectorTo: "Até",
    rangeSelectorZoom: "Periodo",
    downloadPNG: "Download imagem PNG",
    downloadJPEG: "Download imagem JPEG",
    downloadPDF: "Download documento PDF",
    downloadSVG: "Download imagem SVG",
    printChart: "Imprimir Gráfico",
    resetZoom: "Resetar Zoom",
    thousandsSep: ".",
    decimalPoint: ","
  }
});

class ChartSurgeryTime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      loadingModal: false,
      dateRefModal: "",
      dataTable: [],
      loading: true,
      categories: [],
      totalStart: null,
      totalEnd: null,
      series: [
        {
          type: "area",
          name: "Procedimentos",
          color: "#1bbfbb",
          data: []
        }
      ]
    };
  }

  loadData = () => {
    this.setState({
      loading: true,
      totalStart: null,
      totalEnd: null,
      listConvenios: null
    });
    let objFilters = getRequestFilters();

    this.cancellation = new Cancellation();
    this._isMounted = true;

    api
      .post("dashboard/surgery_time_by_day", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation
      })
      .then(res => {
        let self = this;
        if (this._isMounted) {
          const newDiffStart = [];
          const newSugeryAvg = [];
          const newDiffEnd = [];
          const newAboveStart = [];
          const newAboveEnd = [];
          const newAboveDay = [];

          let totalStart = 0;
          let totalEnd = 0;

          res.data.forEach(function(value, index) {
            let date = moment(value.date).format("YYYY-MM-DD");

            if (value.diff_start > 0) {
              totalStart = parseInt(totalStart + value.diff_start);
            }
            if (value.diff_end > 0) {
              totalEnd = parseInt(totalEnd + value.diff_end);
            }

            /*if(value.count_start_above_average){
              newAboveStart.push({x: moment.utc(date).valueOf(), date: value.date,title: value.count_start_above_average, text: 'Procedimentos com tempo médio antes da cirurgia acima da média!'});
            }

            if(value.count_end_above_average){
              newAboveEnd.push({x: moment.utc(date).valueOf(), date: value.date, title: value.count_end_above_average, text: 'Procedimentos com tempo médio depois da cirurgia acima da média!'});
            }*/

            if (
              value.count_start_above_average > 0 ||
              value.count_end_above_average > 0
            ) {
              let sumAbove = parseInt(
                value.count_start_above_average + value.count_end_above_average
              );
              newAboveDay.push({
                x: moment.utc(date).valueOf(),
                date: value.date,
                title: "!",
                text: "Procedimentos com tempo acima da média!"
              });
            }

            newDiffStart.push([moment.utc(date).valueOf(), value.diff_start]);
            newSugeryAvg.push([
              moment.utc(date).valueOf(),
              value.surgery_average
            ]);
            newDiffEnd.push([moment.utc(date).valueOf(), value.diff_end]);
          });

          this.setState({
            loading: false,
            totalEnd: totalStart,
            totalEnd: totalEnd,
            series: [
              {
                type: "areaspline",
                name: "Tempo médio de despertar",
                color: "rgba(27, 191, 187, 0.27)",
                lineColor: "#4bbfbf",
                lineWidth: 4,
                id: "diff_end",
                data: newDiffEnd,
                legendIndex: 3
              },
              {
                type: "areaspline",
                name: "Tempo médio de cirurgia",
                color: "rgba(240, 115, 150, 0.69)",
                lineColor: "#f07396",
                lineWidth: 4,
                id: "surgery_average",
                data: newSugeryAvg,
                legendIndex: 2
              },
              {
                type: "areaspline",
                name: "Tempo médio de indução",
                color: "rgba(27, 191, 187, 0.27)",
                lineColor: "#4bbfbf",
                lineWidth: 4,
                id: "diff_start",
                data: newDiffStart,
                legendIndex: 1
              },
              /* {
                type: "flags",
                onSeries: 'diff_end',
                data: newAboveEnd,
                shape: 'circlepin',
                width: 16,
                color: 'white',
                lineColor: 'red',
                showInLegend: false,
                events: {
                  click: function(event) {
                    console.log(event.point.date)
                  }
              }
              },
              {
                type: "flags",
                onSeries: 'diff_start',
                data: newAboveStart,
                shape: 'circlepin',
                width: 16,
                lineColor: 'red',
                showInLegend: false,
                events: {
                  click: function(event) {
                    console.log(event.point.date)
                  }
              }
              },*/
              {
                type: "flags",
                data: newAboveDay,
                shape: "circlepin",
                width: 16,
                lineColor: "red",
                showInLegend: false,
                style: {
                  color: "red",
                  fontSize: "14px",
                  cursor: "pointer"
                },
                events: {
                  click: function(event) {
                    self.showModal(event.point.date);
                  }
                }
              }
            ]
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  loadDataDetails = date => {
    this.setState({ loadingModal: true });
    let objFilters = getRequestFilters();
    delete objFilters.period;

    this.cancellationDetais = new Cancellation();
    this._isMountedDetails = true;
    api
      .post("dashboard/surgery_time_by_day/" + date, objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellationDetais
      })
      .then(res => {
        if (this._isMountedDetails) {
          const newData = [];
          res.data.forEach(function(value, index) {
            if (value.start_above_average > 0 || value.end_above_average > 0) {
              newData.push(value);
            }
          });

          this.setState({
            loadingModal: false,
            dateRefModal: moment(date).format("DD/MM/YYYY"),
            dataTable: newData
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  }

  componentDidMount() {
    if (this.props.lastfilter) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // cancel request case when pending ...
    if (this.cancellation) {
      this.cancellation.cancel();
      this._isMounted = false;
    }
    if (this.cancellationDetais) {
      this.cancellationDetais.cancel();
      this._isMountedDetails = false;
    }
  }

  showModal = date => {
    this.setState({
      modalVisible: true
    });

    this.loadDataDetails(date);
  };

  handleCancel = e => {
    this.setState({
      modalVisible: false
    });
  };

  render() {
    let options = {
      credits: { enabled: false },
      chart: {
        zoomType: "x",
        marginTop: 20
      },
      title: {
        text: ""
      },
      subtitle: {
        text: ""
      },
      xAxis: {
        type: "datetime"
      },
      yAxis: {
        title: {
          text: "Tempo médio (min)"
        }
      },
      plotOptions: {
        areaspline: {
          stacking: "normal",
          marker: {
            enabled: false
          }
        }
      },
      legend: {
        enabled: true
      },

      series: this.state.series,
      tooltip: {
        split: true,
        valueSuffix: " min",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        borderWidth: 0,
        borderRadius: 5,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0,
          color: "white"
        }
      }
    };

    const columns = [
      {
        title: "Anestesista",
        dataIndex: "anaesthetists",
        key: "anaesthetists",
        render: anaesthetists => <span>{anaesthetists}</span>
      },
      {
        title: "Procedimento",
        dataIndex: "name",
        key: "name",
        render: name => <span className="column-letter">{getOnlyChar(name)}</span>
      },
      {
        title: "Tempo de indução",
        dataIndex: "diff_start",
        key: "diff_start",
        render: (diff_start, record) => {
          if (record.start_above_average) {
            return (
              <span style={{ color: "red" }}>
                {diff_start} <small>(min)</small> &uarr;
              </span>
            );
          } else {
            return (
              <span>
                {diff_start} <small>(min)</small>
              </span>
            );
          }
        }
      },
      {
        title: "Tempo de cirurgia",
        dataIndex: "surgery_total",
        key: "surgery_total",
        render: surgery_total => (
          <span>
            {surgery_total} <small>(min)</small>
          </span>
        )
      },
      {
        title: "Tempo de despertar",
        dataIndex: "diff_end",
        key: "diff_end",
        render: (diff_end, record) => {
          if (record.end_above_average) {
            return (
              <span style={{ color: "red" }}>
                {diff_end} <small>(min)</small> &uarr;
              </span>
            );
          } else {
            return (
              <span>
                {diff_end} <small>(min)</small>
              </span>
            );
          }
        }
      },
      {
        title: "PDF",
        dataIndex: "pdf",
        key: "pdf",
        render: pdf => (
          <a
            style={{ color: "red", fontSize: "18px" }}
            href={
              "javascript:window.location.href='https://s3-us-west-2.amazonaws.com/anestech-homolog-storage/procedure/" +
              pdf +
              "'"
            }
          >
            <Icon type="file-pdf" />
          </a>
        )
      }
    ];

    return (
      <div>
        <Card
          style={{ minHeight: 445, width: "100%" }}
          title={
            <span>
              {"Distribuição do tempo cirurgico diário"}
              {""}
              <small>
                <Divider type="vertical" />
                Selecione período para zoom <Icon type="zoom-in" />
              </small>
            </span>
          }
          extra={
              <Tooltip placement="left" title="Procedimentos com tempo de indução e/ou despertar acima da média">
              <Button shape="circle" icon="exclamation" className="flag-legend" />
              </Tooltip>
          }
        >
          <Spin className="ant-spin-lg" spinning={this.state.loading}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </Spin>

          <Modal
            title="Distribuição de procedimentos com tempo de indução e/ou despertar acima da média"
            key="1"
            visible={this.state.modalVisible}
            onOk={this.handleCancel}
            className="container-modal"
            onCancel={this.handleCancel}
            footer={[
              <Button
                type="primary"
                className="btn-custom-primary"
                key="1"
                onClick={this.handleCancel}
              >
                OK
              </Button>
            ]}
          >
            <Spin className="ant-spin-lg" spinning={this.state.loadingModal}>
              <h4>Data: {this.state.dateRefModal}</h4>
              <Table
                rowKey="id"
                size="midle"
                columns={columns}
                dataSource={this.state.dataTable}
                pagination={false}
              />
            </Spin>
          </Modal>
        </Card>
      </div>
    );
  }
}

export default ChartSurgeryTime;
