import React, { Component } from "react";
import { Alert, Card, Row, Col, Icon } from "antd";
import imgPageConstruct from "../assets/page-construction.png";
import ChartPharmaAnaphylactic from "../components/ChartPharmaAnaphylactic";

class PharmaPage extends Component {

  render() {

    return (
      <div>
        <h1 className="title">Fármacos</h1>

        <Row>
          <ChartPharmaAnaphylactic lastfilter={this.props.lastfilter} />
        </Row>
      </div>
    );
  }
}

export default PharmaPage;
