import React, { Component } from "react";
import {
  Layout,
  Form,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Card,
  message,
  Tooltip,
  Divider,
  Tag,
  Alert,
  Modal
} from "antd";

import api from "../services/api";
import { Link, withRouter } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import { login } from "../services/auth";
import iconColor from "../assets/icon_color.png";
import iconHeader from "../assets/AxDashboard.svg";

const { Content, Footer } = Layout;
const FormItem = Form.Item;

const history = createHistory({
  forceRefresh: true
});

class LoginPage extends Component {
  state = {
    loading: false,
    confirmDirty: false
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("A confirmação de senha não confere!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        
        // request update senha
        try {
          
        const passwordPattern = new RegExp('(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}');

        if(passwordPattern.test(values.password)){

          this.setState({ loading: true });

          let params = {
            hash_validation: this.props.match.params.hash_validation,
            password_new: values.password
          }

          api
            .put("/web/users/passwordUpdate", params, {validateStatus: false})
            .then(res => {
              this.setState({ loading: false });
              if(res.status ==  404){
                message.error(
                    "Usuário não encontrado em nossa base de dados."
                );
                this.setState({ loading: false});
            }
            else{
              Modal.success({
                title: 'Nova senha cadastrada com sucesso!',
                content: 'Faça o login e volte a utilizar novamente.',
                onOk() {
                  history.push("/login");
                  /*login(res.token, res.data);
                  history.push('/app');*/
                }
              });
            }
            })
            .catch(error => {
              console.log(error);
              message.error(
                "Usuário não autorizado, verifique suas credenciais."
              );
              this.setState({ loading: false });
            });

        }
        else{
          message.error(
            "A senha deve conter no mínimo 8 caracteres contendo: Números, Letras Maiúsculas, Letras minúsculas e Caracteres especiais."
          );
          this.setState({ loading: false });
        }

          //this.props.history.push("/app");
        } catch (err) {
          message.error("Falha ao realizar alterar senha, tente novamente mais tarde.");
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Layout
          className="login-page layout"
          style={{ minHeight: "100vh", padding: 0 }}
        >
          <Content style={{ padding: "0 10px" }}>
            <Row type="flex" justify="center" align="middle">
              <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
              <Col
                xs={24}
                sm={20}
                md={18}
                lg={12}
                xl={10}
                xxl={8}
                style={{ paddingTop: "50px" }}
              >
                <div style={{ padding: "30px" }} className="login-form">
                  <Card
                    title={
                      <div style={{ textAlign: "center" }} className="logo">
                        <img
                          src={iconHeader}
                          alt="Logo"
                          className="logo-axreg"
                        />
                        <Divider style={{color: '#343f5c'}}>Resetar senha</Divider>
                        <Alert message="A senha deve conter no mínimo 8 caracteres contendo: Números, Letras Maiúsculas, Letras minúsculas e Caracteres especiais." type="warning" />
                      </div>
                    }
                    bordered={false}
                    style={{ width: "100%" }}
                  >
                    <Form
                      onSubmit={this.handleSubmit}
                      className="login-form"
                    >
                      <FormItem>
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: "Digite a nova senha!",
                            }, {
                              validator: this.validateToNextPassword,
                            }
                          ]
                        })(
                          <Input
                            prefix={
                              <Icon
                                type="lock"
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            type="password"
                            placeholder="Nova senha"
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator("confirm", {
                          rules: [
                            {
                              required: true,
                              message: "Digite a confirmação de senha!"
                            }, {
                              validator: this.compareToFirstPassword,
                            }
                          ]
                        })(
                          <Input
                            prefix={
                              <Icon
                                type="lock"
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            type="password"
                            placeholder="Confirmação de senha"
                            onBlur={this.handleConfirmBlur}
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        <Button
                          block
                          type="primary"
                          loading={this.state.loading}
                          htmlType="submit"
                          className="btn-custom-primary"
                          style={{ float: "rigth" }}
                        >
                          Atualizar senha
                        </Button>

                      </FormItem>
                    </Form>
                  </Card>
                </div>
              </Col>
              <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
            </Row>
          </Content>
          <Footer
            style={{ position: "relative", bottom: "0", textAlign: "center" }}
          >
            Powered by
            <Tooltip title="Anestech">
              {" "}
              <a href="http://www.anestech.com.br/" target="_blank">
                <img src={iconColor} alt="Logo" />{" "}
              </a>
            </Tooltip>
          </Footer>
        </Layout>
      </div>
    );
  }
}
const WrappedNormalLoginForm = Form.create()(LoginPage);

export default WrappedNormalLoginForm;
