import React, { Component } from "react";
import { Button, Card, Spin, Table, Divider } from "antd";
import api from "../services/api";
import cancelXhrAdapter from "axios-cancel";
import { Cancellation } from "axios-cancel/cancel";
import { getRequestFilters } from "../services/filters";
import {getOnlyNumbers, getOnlyChar} from "../Utils";

const { Column } = Table;

class TableProcedures extends Component {
  state = {
    loading: true,
    data: []
  };

  loadData = () => {
    this.setState({ loading: true });
    let objFilters = getRequestFilters();

    this.cancellation = new Cancellation();
    this._isMounted = true;
    api
      .post("dashboard/procedures_rank/", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation
      })
      .then(res => {
        if (this._isMounted) {
          const newData = [];
          res.data.labels.forEach(function(value, index) {
            let time_anesthetic = res.data.tooltips[index][0];
            let time_surgery = res.data.tooltips[index][1];

            newData.push({
              procedure: value,
              time_anesthetic: time_anesthetic,
              time_surgery: time_surgery,
              value: res.data.values[index]
            });
          });

          this.setState({
            loading: false,
            data: newData
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  }

  componentDidMount() {
    if (this.props.lastfilter) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // cancel request case when pending ...
    if (this.cancellation) {
      this.cancellation.cancel();
      this._isMounted = false;
    }
  }

  render() {
    const columns = [
      {
        title: "Procedimento",
        dataIndex: "procedure",
        key: "procedure",
        width: '40%',
        render: procedure => <span className="column-letter">{getOnlyChar(procedure)}</span>
      },
      {
        title: "Cirurgia (tempo médio)",
        dataIndex: "time_surgery",
        key: "time_surgery",
        width: '20%',
        render: time_surgery => <span>{getOnlyNumbers(time_surgery)} <small>(min)</small></span>
      },
      {
        title: "Anestesia (tempo médio)",
        dataIndex: "time_anesthetic",
        key: "time_anesthetic",
        width: '20%',
        render: time_anesthetic => <span>{getOnlyNumbers(time_anesthetic)} <small>(min)</small></span>
      },
      {
        title: "Quantidade",
        dataIndex: "value",
        key: "value",
        width: '10%'
      }
    ];

    return (
      <div>
        <Card
          style={{ minHeight: 445, width: "100%" }}
          title="Tabela de procedimentos realizados no período"
        >
          <Spin className="ant-spin-lg" spinning={this.state.loading}>
            <Table
              rowKey="procedure"
              size="midle"
              className="table-procedure"
              columns={columns}
              dataSource={this.state.data}
              pagination={false}
              scroll={{y: 445 }}
            />
            
          </Spin>
        </Card>
      </div>
    );
  }
}

export default TableProcedures;
