import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
const { Sider } = Layout;

class MenuComponent extends Component {
  constructor(props) {
    super(props);   

    this.state = {
      collapsed: false,
      responsive: false,
    };
  }

  componentWillMount() {
    this.props.history.listen(() => {
      try {
        if(!this.state.collapsed && this.state.responsive){
          this.onCollapse(true);
        }
  
        window.scrollTo(0, 0);

      } catch (error) {
        console.log(error); 
      }
    });
  }


  onCollapse = (collapStatus, type) => {
    if(type === 'responsive'){
      this.setState({ collapsed: collapStatus, responsive: collapStatus});
    }
    else{
      this.setState({ collapsed: collapStatus });
    }    
  }


  render() {
    const routerHome = ["/", "/app", "/app/"];
    const menuKey =
      routerHome.indexOf(this.props.history.location.pathname) === -1
        ? this.props.history.location.pathname
        : "/app/home";

    return (
      <Sider
        width={200}
        className="menu-content"
        breakpoint="xl"
        collapsedWidth="0"
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[menuKey]}
          style={{ height: "100%", borderRight: 0 }}
        >
          <Menu.Item key="/app/home">
            <Link to="/app/home">
              {" "}
              <span>Visão geral</span>{" "}
            </Link>
          </Menu.Item>
          
          <Menu.Item key="/app/time-surgery">
            <Link to="/app/time-surgery">
              {" "}
              <span>Tempo cirúrgico</span>{" "}
            </Link>
          </Menu.Item>

          <Menu.Item key="/app/pharma">
            <Link to={{ pathname: "/app/pharma", state: { collapsed: true }}}>
              {" "}
              <span>Fármacos</span>{" "}
            </Link>
          </Menu.Item>

          <Menu.Item key="/app/gases">
            <Link to="/app/gases">
              {" "}
              <span>Gases</span>{" "}
            </Link>
          </Menu.Item>

          
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(MenuComponent);
