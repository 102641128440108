import React, { Component } from "react";
import { Alert, Card, Row, Col, Icon } from "antd";
import imgPageConstruct from "../assets/page-construction.png";
import ChartSurgeryTime from "../components/ChartSurgeryTime";


class PharmaPage extends Component {

  render() {

    return (
      <div>
        <h1 className="title">Tempo cirúrgico</h1>

        <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="col-padding">
          <ChartSurgeryTime lastfilter={this.props.lastfilter} />
          </Col>
        </Row>
        
      </div>
    );
  }
}

export default PharmaPage;
