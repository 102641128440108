import React, { Component } from "react";
import { Card, Spin, Button, Tooltip, Modal, Divider } from "antd";
import api from "../../services/api";
import cancelXhrAdapter from 'axios-cancel';
import {Cancellation} from 'axios-cancel/cancel';
import { getRequestFilters } from "../../services/filters";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class IndicatorTurnover extends Component {
  state = {
    loading: true,
    value: null,
    total: null,
    series: [{
      data: []
    }]
  };

  showModal = () => {
    this.setState({
      modalVisible: true
    });
  };

  handleCancel = e => {
    this.setState({
      modalVisible: false
    });
  };

  loadData = () => {
    this.setState({ loading: true, value: null, total: null });
    let objFilters = getRequestFilters();

    this.cancellation = new Cancellation();
    this._isMounted = true;
    api
      .post("dashboard/surgery_center_rooms_activity/", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation
      })
      .then(res => {
        if (this._isMounted) {
        this.setState({
          loading: false,
          value: res.data.percent.toString(),
          total: res.data.total.toString(),
          series: [{
            name: 'Gases',
            colorByPoint: true,
            data: [{ "name": 'Ativas', "y": res.data.active}, {"name": 'Inativas', "y": res.data.inactive}]
          }]
        });
      }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  }

  componentDidMount() {
    if (this.props.lastfilter) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // cancel request case when pending ...
    if(this.cancellation) {
      this.cancellation.cancel();
      this._isMounted = false;
    }
  }

  render() {
    let options = {
      credits: { enabled: false },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        margin: [0, 0, 80, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: -5,
        spacingRight: 0,
        height: '325',
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: ' {point.percentage:.1f}% ({point.y} salas)',
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        borderWidth: 0,
        borderRadius: 5,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0,
          color: "white"
        }
      },
      
      legend: {
        align: 'left',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        x: 0,
        y: 0
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true,
          size: '100%',
        }
      },
      colors: [
        "#36a2eb",
        "#6fcec5"
      ],
      series: this.state.series
    };

    return (
      <div>
        <Card className="card-indicator">
  
          <div className="card-number">
            <span>
              <Spin className="ant-spin-lg" spinning={this.state.loading}>
                &nbsp;
                {this.state.value}
                <small>
                  {this.state.value ? "%" : ""}
                </small>
              </Spin>
            </span>
          </div>
          <div className="card-sub-title">Salas ativas</div>
          <div className="card-info">
            <Tooltip
              placement="topLeft"
              title="Salas ativas no período informado."
            >
              <Button shape="circle" icon="info" className="btn-card-details" />
            </Tooltip>
          </div>
        </Card>

        <Modal
          title="Cenário de salas"
          zIndex="565565"
          visible={this.state.modalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              className="btn-custom-primary"
              onClick={this.handleCancel}
              key="1"
            >
              OK
            </Button>
          ]}
        >
        <p className="text-indicator-room">Total de salas<Divider type="vertical"></Divider>{this.state.total}</p>
          <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
        </Modal>
      </div>
    );
  }
}

export default IndicatorTurnover;
