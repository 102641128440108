import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../services/auth";
import {
  Layout,
  Menu,
  Row,
  Col,
  Button,
  Select,
  Avatar,
  Dropdown,
  Divider,
  DatePicker,
  Skeleton,
  Radio,
  Spin,
  Tag,
  Icon,
  Input
} from "antd";
import moment from "moment";
import "moment/locale/pt-br";
import locale from "antd/lib/date-picker/locale/pt_BR";
import iconHeader from "../assets/AxDashboard.svg";
import iconLogo from "../assets/AxReg_icon.svg";
import { PeriodSubtractMonth, firstLetterUpper } from "../Utils";
import api from "../services/api";
import { getFilters, setFilters } from "../services/filters";
import { getProfile } from "../services/auth";

const { Header } = Layout;
const Option = Select.Option;
const { RangePicker } = DatePicker;

const Search = Input.Search;

const SelectInstituicao = props => {
  const options = props.institutions.map(d => (
    <Option key={d.id}>{d.name}</Option>
  ));
  let count = props.institutions.length;

  return (
    <div>
      {props.user.id == 1 ? (
        <div className="container-select-mobile">
          {props.type == "mobile" ? (
            <Select
              value={props.institution ? props.institution : undefined}
              showSearch
              onChange={props.selected}
              allowClear={props.institutions.length == 1 ? false : true}
              notFoundContent="Não encontrado"
              style={{ width: props.width }}
              placeholder={"Todas instituições (" + count + ")"}
              optionFilterProp="children"
              className="select-institution-mobile"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {options}
            </Select>
          ) : (
            <Select
              value={props.institution ? props.institution : undefined}
              showSearch
              onChange={props.selected}
              allowClear={props.institutions.length == 1 ? false : true}
              notFoundContent="Não encontrado"
              style={{ width: props.width }}
              placeholder={"Todas instituições (" + count + ")"}
              optionFilterProp="children"
              className="select-institution"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {options}
            </Select>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

class HeaderComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      initLoading: true,
      listInstitutions: [],
      shortDate: "", //number month
      labelDays: null,
      institution: null,
      period: [],
      user: getProfile(),
      institutionDropdownArrow: "down",
      institutionSelected: {}
    };

  }


  institutionArrowChange = a => {
    if (a) {
      this.setState({ institutionDropdownArrow: "up" });
    } else {
      this.setState({ institutionDropdownArrow: "down" });
    }
  };

  locationHome = e => {
    window.location = window.location.origin + "/app/home";
  };

  HandleLogout = e => {
    logout();
  };

  FormatLabelDays = (dateIni, dateFim) => {
    const from = moment(dateIni, "DD/MM/YYYY");
    const to = moment(dateFim, "DD/MM/YYYY");
    let diff = to.diff(from, "days");
    let returnLabel = "";

    switch (true) {
      case diff == 0:
        returnLabel = "1 dia";
        break;
      case diff == 1:
        returnLabel = diff + " dia";
        break;
      default:
        returnLabel = diff + " dias";
        break;
    }

    return returnLabel;
  };

  InitFilters = () => {    
    this.setState({ initLoading: true });
    let filtersExists = getFilters();

    if (filtersExists) {

      let defaultInstitutionSelected = {};

      if (this.state.user.id == 1) {
        defaultInstitutionSelected = filtersExists.listInstitutions.filter(
          function(el) {
            return el.id == 1;
          }
        )[0];
      } else {
        defaultInstitutionSelected = filtersExists.listInstitutions.filter(
          function(el) {
            return el.id == filtersExists.institution;
          }
        )[0];
      }

      let objState = {
        initLoading: false,
        shortDate: filtersExists.shortDate,
        listInstitutions: filtersExists.listInstitutions,
        period: filtersExists.period,
        institution: filtersExists.institution,
        institutionSelected: defaultInstitutionSelected,
        labelDays: this.FormatLabelDays(
          filtersExists.period[0],
          filtersExists.period[1]
        )
      };

      this.setState(objState, () => {
        this.SetStateFilters(); // set state global App
      });
    } else {
      api
        .get("/web/institutions/")
        .then(res => {
          let defaultInstitution = null;
          let defaultInstitutionSelected = {};

          if (this.state.user.id == 1) {
            defaultInstitutionSelected = res.data.filter(function(el) {
              return el.id == '1';
            })[0];
          } else {
            defaultInstitution = res.data[0].id.toString();
            defaultInstitutionSelected = res.data.filter(function(el) {
              return el.id == res.data[0].id.toString();
            })[0];
          }


          const objState = {
            initLoading: false,
            shortDate: "1",
            listInstitutions: res.data,
            period: PeriodSubtractMonth(1),
            institution: defaultInstitution,
            institutionSelected: defaultInstitutionSelected,
            labelDays: this.FormatLabelDays(
              PeriodSubtractMonth(1)[0],
              PeriodSubtractMonth(1)[1]
            )
          };

          this.setState(objState, () => {
            this.SetStateFilters(); // set state global App
            this.SetStateStorage(); // save local storage
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  changeShortPeriod = e => {
    this.setState(
      {
        period: PeriodSubtractMonth(e.target.value),
        shortDate: e.target.value
      },
      () => {
        this.SetStateFilters(); // set state global App
        this.SetStateStorage(); // save local storage
      }
    );
  };

  // set state filter period - component -> ant design
  changePeriod = (dates, dateSelected) => {
    this.setState(
      {
        period: dateSelected,
        shortDate: "0",
        labelDays: this.FormatLabelDays(dates[0], dates[1])
      },
      () => {
        this.SetStateFilters(); // set state global App
        this.SetStateStorage(); // save local storage
      }
    );
  };

  changeInstitution = value => {
    
    let id = value ? value : null;

    // verify user administrator anestech
    if (this.state.user.id !== 1) {
      let defaultInstitutionSelected = value
        ? this.state.listInstitutions.filter(function(el) {
            return el.id == value;
          })[0]
        : null;

      this.setState(
        { institution: id, institutionSelected: defaultInstitutionSelected },
        () => {
          this.SetStateFilters(); // set state global App
          this.SetStateStorage(); // save local storage
        }
      );
    } else {
      this.setState({ institution: id }, () => {
        this.SetStateFilters(); // set state global App
        this.SetStateStorage(); // save local storage
      });
    }
  };

  // set state global filters
  SetStateFilters = () => {
    let timestamp = new Date(moment().format("YYYY-MM-DDT00:00:ss").toString()).getTime();
    this.props.setglobalstate(timestamp); // edit state global App
  };

  // set state filter localstorage
  SetStateStorage = () => {
    let objState = {
      shortDate: this.state.shortDate,
      listInstitutions: this.state.listInstitutions,
      period: this.state.period,
      institution: this.state.institution
    };
    setFilters(objState); // save local storage
  };

  onClickInstitution = (item, key, keyPath) => {
    this.setState({ institutionDropdownArrow: "down" });
    this.changeInstitution(parseInt(key));
  };


  componentDidMount() {

    this.InitFilters();
  }

  render() {
    const menuUser = (
      <Menu
        className="dropdown-institution-menu"
        onClick={this.onClickInstitution}
      >
        <Menu.Item disabled={true} key="-2">
          <div className="ant-list-item-meta">
            <div className="ant-list-item-meta-content">
              <h4
                className="ant-list-item-meta-title"
                style={{ marginBottom: -5 }}
              >
                {this.state.user.email}
              </h4>
              <div className="ant-list-item-meta-description">
                <small>Administrador</small>
              </div>
            </div>
          </div>
        </Menu.Item>

        {this.state.user.id !== 1 ? (
          ""
        ) : (
          <Menu.Divider>
            <Menu.Item disabled={true} key="-5">
              <SelectInstituicao
                type="mobile"
                institutions={this.state.listInstitutions}
                institution={this.state.institution}
                selected={this.changeInstitution}
                user={this.state.user}
              />
            </Menu.Item>
          </Menu.Divider>
        )}

        {this.state.user.id !== 1 &&
        this.state.listInstitutions &&
        this.state.listInstitutions.length > 1
          ? this.state.listInstitutions.map(institution => {
              return (
                <Menu.Divider>
                  <Menu.Item key={institution.id}>
                    <div className="ant-list-item-meta">
                      <div className="ant-list-item-meta-avatar">
                        <Avatar
                          shape="square"
                          src={
                            institution.logo
                              ? `https://s3-us-west-2.amazonaws.com/anestech-homolog-storage/institution_logo/${
                                  institution.logo
                                }`
                              : null
                          }
                          className="institution-logo"
                        >
                          Logo
                        </Avatar>
                      </div>
                      <div className="ant-list-item-meta-content">
                        <h4
                          className="ant-list-item-meta-title"
                          style={{ marginTop: 8 }}
                        >
                          {institution.name}
                        </h4>
                      </div>
                    </div>
                  </Menu.Item>
                </Menu.Divider>
              );
            })
          : ""}

        <Menu.Divider />

        <Menu.Item className="dropdown-logout" key="logout" disabled={true}>
          <a
            onClick={this.HandleLogout}
            rel=""
            href=""
            className="logout"
          >
            Encerrar sessão
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Header className="header-component">
          <Row type="flex" justify="start">
            <Col xs={0} xl={4} xxl={3}>
              <a onClick={this.locationHome}>
                <img src={iconHeader} alt="Logo Axreg" height={50} />
              </a>
            </Col>
            <Col xs={2} xl={0}>
              <img src={iconLogo} alt="Logo AxReg" height={50} />
            </Col>

            <Col xs={20} xl={15} xxl={17} className="col-filter-date">
              <Skeleton
                className="institutions-skeleton"
                active
                loading={this.state.initLoading}
                paragraph={false}
              >
                <div style={{ display: "inline-block" }}>
                  <SelectInstituicao
                    type="desktop"
                    institutions={this.state.listInstitutions}
                    institution={this.state.institution}
                    selected={this.changeInstitution}
                    user={this.state.user}
                  />
                </div>

                {this.state.labelDays ? (
                  <Tag className="tag-days">{this.state.labelDays}</Tag>
                ) : (
                  ""
                )}

                <RangePicker
                  locale={locale}
                  value={[
                    moment(this.state.period[0], "DD/MM/YYYY"),
                    moment(this.state.period[1], "DD/MM/YYYY")
                  ]}
                  format="DD/MM/YYYY"
                  ranges={{
                    Hoje: [moment(), moment()],
                    Mês: [moment().subtract(parseInt(1), "months"), moment()],
                    Trimestre: [
                      moment().subtract(parseInt(3), "months"),
                      moment()
                    ],
                    Semestre: [
                      moment().subtract(parseInt(6), "months"),
                      moment()
                    ]
                  }}
                  onChange={this.changePeriod}
                  allowClear={false}
                />
                <Divider type="vertical" className="tag-days" />
              </Skeleton>
            </Col>

            <Col xs={2} xl={5} xxl={4} style={{textAlign: "left"}}>
              <Skeleton
                active
                loading={this.state.initLoading}
                paragraph={false}
                avatar={{ shape: "square", style: { verticalAlign: "middle" } }}
                title={{ width: 200 }}
              >

                
                <Dropdown
                  overlay={menuUser}
                  trigger={["click"]}
                  onVisibleChange={this.institutionArrowChange}
                  
                >
                  <Row
                    className="institution-selected"
                  >
                    <Col xs={24} xl={5} style={{ width: "40px" }}>
                      {this.state.institutionSelected ? (
                        <Avatar
                          shape="square"
                          src={
                            "https://s3-us-west-2.amazonaws.com/anestech-homolog-storage/institution_logo/" +
                            this.state.institutionSelected.logo
                          }
                          className="institution-logo"
                        >
                          Logo
                        </Avatar>
                      ) : (
                        <Avatar shape="square" className="institution-logo">
                          Logo
                        </Avatar>
                      )}
                    </Col>
                    <Col
                      xs={0}
                      xl={17}
                      style={{
                        width: "calc(100% - 66px)",
                        marginLeft: "5px"
                      }}
                      className={"institution-user"}
                    >
                      <div style={{width: '100%'}}>
                        <span
                          style={{ float: "left" }}
                          className="first-letter"
                        >
                          {this.state.institutionSelected.name}
                        </span>
                        <small style={{ float: "left" }}>
                          {this.state.user.email}
                        </small>
                      </div>
                    </Col>
                    <Col xs={0} xl={2} style={{ width: "21px" }}>
                      <Icon
                        type={this.state.institutionDropdownArrow}
                        style={{ color: "rgb(124, 133, 149)" }}
                      />
                    </Col>
                  </Row>
                </Dropdown>
              
                
              </Skeleton>
            </Col>
          </Row>
        </Header>
      </div>
    );
  }
}

export default HeaderComponent;
