import React, { Component } from "react";
import { Card, Spin } from "antd";
import api from "../../services/api";
import cancelXhrAdapter from 'axios-cancel';
import {Cancellation} from 'axios-cancel/cancel';
import { getRequestFilters } from "../../services/filters";

class IndicatorPacientes extends Component {
  state = {
    loading: true,
    value: null
  };

  loadData = () => {
      this.setState({ loading: true, value: null, listConvenios: null });
      let objFilters = getRequestFilters();

      this.cancellation = new Cancellation();
      this._isMounted = true;
      api
        .post("dashboard/total/patients/", objFilters, {
          adapter: cancelXhrAdapter,
          cancellation: this.cancellation
        })
        .then(res => {
          if (this._isMounted) {
          this.setState({
            loading: false,
            value: res.data.total.toString()
          });
        }
        })
        .catch(error => {
          console.log(error);
        });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  }

  componentDidMount() {
    if (this.props.lastfilter) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // cancel request case when pending ...
    if(this.cancellation) {
      this.cancellation.cancel();
      this._isMounted = false;
    }
  }

  render() {
    return (
      <div>
        <Card className="card-indicator">
          <div className="card-number">
            <span>
              <Spin className="ant-spin-lg" spinning={this.state.loading}>
                &nbsp;
                {this.state.value}
              </Spin>
            </span>
          </div>
          <div className="card-sub-title">Pacientes atendidos</div>
        </Card>
      </div>
    );
  }
}

export default IndicatorPacientes;
