import React, { Component } from "react";
import { Button, Card, Spin, Select, TimePicker, Divider } from "antd";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import api from "../services/api";
import cancelXhrAdapter from "axios-cancel";
import { Cancellation } from "axios-cancel/cancel";
import { getRequestFilters } from "../services/filters";

const Option = Select.Option;

class ChartHourInterval extends Component {
  state = {
    loading: true,
    series: [
      {
        data: []
      }
    ],
    period_ini: "07:30",
    period_fin: "08:30"
  };

  loadData = () => {
    this.setState({ loading: true });
    let objFilters = getRequestFilters();
    objFilters.period_hour = [this.state.period_ini, this.state.period_fin];

    this.cancellation = new Cancellation();
    this._isMounted = true;
    api
      .post("dashboard/total/procedure_start/", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation
      })
      .then(res => {
        if (this._isMounted) {
          this.setState({
            loading: false,

            series: [
              {
                name: "Procedimentos",
                innerSize: "70%",
                showInLegend: true,
                dataLabels: {
                  enabled: false
                },
                colorByPoint: true,
                data: [
                  {
                    name: "Entre horários",
                    color: "#f07396",
                    y: res.data.started,
                    sliced: true,
                    selected: true
                  },
                  { name: "Total", color: "#4bbfbf", y: res.data.total }
                ]
              }
            ]
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  }

  componentDidMount() {
    if (this.props.lastfilter) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // cancel request case when pending ...
    if (this.cancellation) {
      this.cancellation.cancel();
      this._isMounted = false;
    }
  }

  onChangeIni = (time, timeString) => {
    if (timeString) {
      this.setState({ period_ini: timeString }, () => {
        this.loadData();
      });
    }
  };

  onChangeFin = (time, timeString) => {
    if (timeString) {
      this.setState({ period_fin: timeString }, () => {
        this.loadData();
      });
    }
  };

  render() {
    let options = {
      credits: { enabled: false },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        height: "325"
      },
      title: {
        text: ""
      },
      tooltip: {
        pointFormat: " {point.percentage:.0f}% - {point.y} Cirurgia(s)",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        borderWidth: 0,
        borderRadius: 5,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0,
          color: "white"
        }
      },
      legend: {
        layout: "vertical",
        align: "left",
        verticalAlign: "bottom",
        floating: true
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false
          },
          showInLegend: true,
          size: "70%"
        }
      },
      series: this.state.series
    };

    return (
      <div>
        <Card
          style={{ minHeight: 445, width: "100%" }}
          title="Ocupação de salas entre"
          extra={
            <div>
              <TimePicker
                size="small"
                placeholder=" "
                minuteStep={30}
                onChange={this.onChangeIni}
                value={moment(this.state.period_ini, "HH:mm")}
                format="HH:mm"
              />
              <Divider type="vertical" />
              <TimePicker
                size="small"
                placeholder=" "
                minuteStep={30}
                onChange={this.onChangeFin}
                value={moment(this.state.period_fin, "HH:mm")}
                format="HH:mm"
              />
            </div>
          }
        >
          <Spin className="ant-spin-lg" spinning={this.state.loading}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              oneToOne={true}
            />
          </Spin>
        </Card>
      </div>
    );
  }
}

export default ChartHourInterval;
