import React, { Component } from 'react';
import { Button, Card, Spin, Select, Modal  } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import api from "../services/api";
import cancelXhrAdapter from 'axios-cancel';
import {Cancellation} from 'axios-cancel/cancel';
import { getRequestFilters } from "../services/filters";
import {sortArray} from "../Utils";

class ChartAfterSurgery extends Component {

  state = {
    loading: true,
    categories: [],
    series: [{
      showInLegend: false,
      name: " ",
      data: []
    }]
  }


  
  showModal = () => {
    this.setState({
      modalVisible: true
    });
  };

  handleCancel = e => {
    this.setState({
      modalVisible: false
    });
  };


  loadData = () => {

    this.setState({ 'loading': true });
    let objFilters = getRequestFilters();

    this.cancellation = new Cancellation();
    this._isMounted = true;
    api
      .post("dashboard/procedure_room_types/", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation
      })
      .then(res => {

        if (this._isMounted) {

        this.setState({
          loading: false,
          categories: res.data.labels, 
          series: [{
            showInLegend: false,
            name: " ",
            data: res.data.values.sort(sortArray("-"))
          }]
        });

      }


      })
      .catch(error => {
        console.log(error);
      });

  };


  componentWillReceiveProps(nextProps) {

    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  };


  componentDidMount() {
    if (this.props.lastfilter) {
      this.loadData();
    }
  };

  componentWillUnmount() {
    // cancel request case when pending ...
    if(this.cancellation) {
      this.cancellation.cancel();
      this._isMounted = false;
    }
  }

  render() {

    let options = {
      chart: {
        type: "bar",
        height: '325'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      xAxis: {
        categories: this.state.categories
      },
      yAxis: {
        min: 0,
        title: {
          text: "Quantidade de pacientes"
        }
      },
      plotOptions: {
        bar: {
          colorByPoint: true
        }
      },
      colors: [
        "rgb(54, 162, 235)",
        "rgb(54, 162, 235, 0.90)",
        "rgb(54, 162, 235, 0.80)",
        "rgb(54, 162, 235, 0.70)",
        "rgb(54, 162, 235, 0.60)",
        "rgb(54, 162, 235, 0.50)",
        "rgb(54, 162, 235, 0.40)",
        "rgb(54, 162, 235, 0.30)",
        "rgb(54, 162, 235, 0.20)"
      ],
      series: this.state.series,
      tooltip: {
        pointFormat: '{point.y} pacientes',
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        borderWidth: 0,
        borderRadius: 5,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0,
          color: "white"
        }
      }
    };

    return (
      <div>
        <Card
          style={{ minHeight: 445 }}
          title="Destino do paciente pós cirúrgico"
        >
          <Spin className="ant-spin-lg" spinning={this.state.loading}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
          </Spin>
        </Card>
      </div>
    );
  }
}

export default ChartAfterSurgery;