import React, { Component } from "react";
import { Button, Card, Row, Col, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";
import ListAnaesthetists from "../components/ListAnaesthetists";
import ChartTopPharma from "../components/ChartTopPharma";
import ChartAnestheticsInterval from "../components/ChartAnestheticsInterval";
import ChartTopGases from "../components/ChartTopGases";
import ChartHourProcedure from "../components/ChartHourProcedure";
import ChartHourInterval from "../components/ChartHourInterval";
import CarouselIndicators from "../components/CarouselIndicators";
import TableProcedures from "../components/TableProcedures";
import ChartRooms from "../components/ChartRooms";
import ChartAfterSurgery from "../components/ChartAfterSurgery";

class HomePage extends Component {

  constructor(props) {
    super(props);    
  }

  render() {
    return (
      <div>
        <h1 className="title">Visão geral</h1>

        <Row>
          <Col span={24} style={{ padding: "15px 50px 50px" }}>
            <CarouselIndicators lastfilter={this.props.lastfilter} />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className="col-padding">
            <ListAnaesthetists lastfilter={this.props.lastfilter} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16} className="col-padding">
            <ChartAnestheticsInterval lastfilter={this.props.lastfilter} />
          </Col>
        </Row>
        <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={17} className="col-padding">
            <ChartTopPharma lastfilter={this.props.lastfilter}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={7} className="col-padding">
            <ChartTopGases lastfilter={this.props.lastfilter} />
          </Col>
        </Row>
        <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={17} className="col-padding">
            <ChartHourProcedure lastfilter={this.props.lastfilter} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={7} className="col-padding">
            <ChartHourInterval lastfilter={this.props.lastfilter} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={12} className="col-padding">
            <ChartRooms lastfilter={this.props.lastfilter} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={12} className="col-padding">
            <ChartAfterSurgery lastfilter={this.props.lastfilter} />
          </Col>
        </Row>

        <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="col-padding">
          <TableProcedures lastfilter={this.props.lastfilter} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default HomePage;
