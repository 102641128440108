import React, { Component } from "react";
import { Button, Card, Icon, Spin, Divider } from "antd";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import api from "../services/api";
import cancelXhrAdapter from "axios-cancel";
import { Cancellation } from "axios-cancel/cancel";
import { getRequestFilters } from "../services/filters";

Highcharts.setOptions({
  lang: {
    loading: "Carregando ...",
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],
    weekdays: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado"
    ],
    shortMonths: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez"
    ],
    exportButtonTitle: "Exportar",
    printButtonTitle: "Imprimir",
    rangeSelectorFrom: "De",
    rangeSelectorTo: "Até",
    rangeSelectorZoom: "Periodo",
    downloadPNG: "Download imagem PNG",
    downloadJPEG: "Download imagem JPEG",
    downloadPDF: "Download documento PDF",
    downloadSVG: "Download imagem SVG",
    printChart: "Imprimir Gráfico",
    resetZoom: "Resetar Zoom",
    thousandsSep: ".",
    decimalPoint: ","
  }
});

class ChartAnestheticsInterval extends Component {
  state = {
    loading: true,
    categories: [],
    total: null,
    series: [
      {
        type: "area",
        name: "Procedimentos",
        color: "#1bbfbb",
        data: []
      }
    ]
  };

  loadData = () => {
    this.setState({ loading: true, value: null, listConvenios: null });
    let objFilters = getRequestFilters();

    this.cancellation = new Cancellation();
    this._isMounted = true;
    api
      .post("dashboard/total/procedures_per_day", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation
      })
      .then(res => {
        if (this._isMounted) {
          const newData = [];
          let total = 0;
          res.data.forEach(function(value, index) {
            total = parseInt(total + value.count);
            let date = moment(value.date).format("YYYY-MM-DD");
            newData.push([moment.utc(date).valueOf(), value.count]);
          });

          this.setState({
            loading: false,
            total: total,
            series: [
              {
                type: "area",
                name: "Procedimentos",
                color: "#1bbfbb",
                data: newData
              }
            ]
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  }

  componentDidMount() {
    if (this.props.lastfilter) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // cancel request case when pending ...
    if (this.cancellation) {
      this.cancellation.cancel();
      this._isMounted = false;
    }
  }

  render() {
    let options = {
      credits: { enabled: false },
      chart: {
        zoomType: "x",
        height: "325"
      },
      title: {
        text: ""
      },
      subtitle: {
        text: ""
      },
      xAxis: {
        type: "datetime"
      },
      yAxis: {
        title: {
          text: "Procedimentos"
        }
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [[0, "#ddf6f6"], [1, "#1bbfbb"]]
          }
        }
      },
      legend: {
        enabled: false
      },

      series: this.state.series,
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        borderWidth: 0,
        borderRadius: 5,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0,
          color: "white"
        }
      }
    };

    return (
      <div>
        <Card
          style={{ minHeight: 445, width: "100%" }}
          title={
            <span>
              {"Distribuição de procedimentos no período"}
              {""}
              <small>
                <Divider type="vertical" />
                Selecione período para zoom <Icon type="zoom-in" />
              </small>
            </span>
          }
          extra={
            <Spin spinning={this.state.loading}>
              <span className="total-procedure">
                {" "}
                {this.state.total ? "Total" : ""}
                <Divider type="vertical" />
                {this.state.total}
              </span>
            </Spin>
          }
        >
          <Spin className="ant-spin-lg" spinning={this.state.loading}>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </Spin>
        </Card>
      </div>
    );
  }
}

export default ChartAnestheticsInterval;
