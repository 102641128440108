import React, { Component } from "react";
import { Alert, Card, Row, Col } from "antd";
import imgPageConstruct from "../assets/page-construction.png";

class PharmaPage extends Component {
  render() {

    return (
      <div>
        <h1 className="title">Gases</h1>

        <Row>
          <Col>
            <Card>
              <Alert
                message="Grandes novidades estão a caminho"
                description="Estamos trabalhando pra oferecer a melhor experiência para você. Logo mais esta tela estará disponível. Obrigado!"
                type="warning"
                showIcon
                className="alert-custom"
              />
              
            </Card>

            <div style={{ textAlign: "center", marginTop: '30px' }}>
                <img src={imgPageConstruct} alt="Pagina em construção" />
              </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PharmaPage;
