import React, { Component } from "react";
import { Card, Spin, Button, Tooltip, Modal, Table } from "antd";
import api from "../../services/api";
import { ConvertSecondsToHourMinute, sortArray } from "../../Utils";
import cancelXhrAdapter from "axios-cancel";
import { Cancellation } from "axios-cancel/cancel";
import { getRequestFilters } from "../../services/filters";

class IndicatorTurnover extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      hour: null,
      minute: null,
      data: []
    };

  }

  showModal = () => {
    this.setState({
      modalVisible: true
    });
  };

  handleCancel = e => {
    this.setState({
      modalVisible: false
    });
  };

  loadData = () => {    
    this.setState({ loading: true, hour: null, minute: null });
    let objFilters = getRequestFilters();

    this._isMounted = true;

    this.cancellation = new Cancellation();
    api
      .post("dashboard/total/turnover/", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation
      })
      .then(res => {
        if (this._isMounted) {
          let arrayTime = ConvertSecondsToHourMinute(res.data.avg_turnover);
          this.setState({
            loading: false,
            hour: arrayTime[0].toString(),
            minute: arrayTime[1].toString()
          });
        }
      })
      .catch(error => {
        console.log(error);
      });

    this.cancellation2 = new Cancellation();
    api
      .post("dashboard/turnovers/", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation2
      })
      .then(res => {
        if (this._isMounted) {
          const newData = [];

          let arrayRooms = res.data.rooms.sort(sortArray("-avgTurnover"));

          arrayRooms.forEach(function(value, index) {
            let arrayTime = ConvertSecondsToHourMinute(value.avgTurnover);
            newData.push({
              center: value.center,
              room: value.room,
              avgTurnover: arrayTime[0] + "h " + arrayTime[1]
            });
          });

          this.setState({
            data: newData
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  
  
  componentWillReceiveProps(nextProps) {    
    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  }

  componentDidMount() {

    if (this.props.lastfilter) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // cancel request case when pending ...
    if (this.cancellation) {
      this.cancellation.cancel();
    }

    if (this.cancellation2) {
      this.cancellation2.cancel();
    }

    this._isMounted = false;
  }

  render() {
    const columns = [
      {
        title: "Centro cirúrgico",
        dataIndex: "center",
        key: "center",
        width: 220
      },
      {
        title: "Sala",
        key: "room",
        dataIndex: "room"
      },
      {
        title: "Média de Turnover",
        dataIndex: "avgTurnover",
        key: "avgTurnover",
        width: 200
      }
    ];
    return (
      <div>
        <Card className="card-indicator">
          <div className="card-details">
            <Tooltip placement="topLeft" title="Visualizar detalhes">
              <Button
                shape="circle"
                key="1"
                icon="search"
                className="btn-card-details"
                onClick={this.showModal}
              />
            </Tooltip>
          </div>
          <div className="card-number">
            <span>
              <Spin className="ant-spin-lg" spinning={this.state.loading}>
                &nbsp;
                {this.state.hour}
                <small>
                  {this.state.hour ? "h" : ""} {this.state.minute ? this.state.minute + "'" : ""} 
                </small>
              </Spin>
            </span>
          </div>
          <div className="card-sub-title">Média do Turnover de Salas</div>
          <div className="card-info">
            <Tooltip
              placement="topLeft"
              title="Tunorver é o tempo médio em que cada sala fica ociosa na instituição."
            >
              <Button shape="circle" icon="info" className="btn-card-details" />
            </Tooltip>
          </div>
        </Card>

        <Modal
          title="Tabela de média de turnover por sala"
          zIndex="565565"
          visible={this.state.modalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="container-modal"
          footer={[
            <Button
              type="primary"
              className="btn-custom-primary"
              onClick={this.handleCancel}
              key="1"
            >
              OK
            </Button>
          ]}
        >
          <Table
            size="midle"
            className="table-turnover"
            columns={columns}
            dataSource={this.state.data}
            pagination={false}
            scroll={{ y: 445 }}
            rowKey="room"
          />
          ,
        </Modal>
      </div>
    );
  }
}

export default IndicatorTurnover;
