import React, { Component } from "react";
import { Card, Spin, Select, List, Progress, Col } from "antd";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import api from "../services/api";
import cancelXhrAdapter from "axios-cancel";
import { Cancellation } from "axios-cancel/cancel";
import { getRequestFilters } from "../services/filters";

const Option = Select.Option;

class ChartOccupation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingWeeks: true,
      series: [
        {
          data: []
        }
      ],
      title: {
        text: ""
      },
      timeWeeks: []
    };

    this.loadData = this.loadData.bind(this);
  }


  loadData = () => {
    const self = this;

    this.setState({ loading: true });
    let objFilters = getRequestFilters();

    this.cancellation = new Cancellation();
    this._isMounted = true;
    api
      .post("dashboard/surgery_center_rooms_activity/", objFilters, {
        adapter: cancelXhrAdapter,
        cancellation: this.cancellation
      })
      .then(res => {
        if (this._isMounted) {
          this.setState({
            loading: false,

            series: [
              {
                name: "Salas",
                innerSize: "70%",
                showInLegend: true,
                dataLabels: {
                  enabled: false
                },
                colorByPoint: true,
                data: [
                  {
                    name: "Ativas",
                    color: "#6fcec5",
                    y: res.data.active
                  },
                  { name: "Inativas", color: "#c9cbcf", y: res.data.inactive }
                ]
              }
            ],
            title: {
              text: res.data.total.toString() + "<br>salas",
              align: "center",
              verticalAlign: "middle",
              y: 0
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({ loadingWeeks: true });

    this.cancellationWeekDay = new Cancellation();
    this.cancellationWeekEnd = new Cancellation();

    axios
      .all([
        api.post("dashboard/total/weekdays", objFilters, {
          adapter: cancelXhrAdapter,
          cancellation: this.cancellationWeekDay
        }),
        api.post("dashboard/total/weekends", objFilters, {
          adapter: cancelXhrAdapter,
          cancellation: this.cancellationWeekEnd
        })
      ])
      .then(
        axios.spread(function(weekdays, weekends) {
          if (self._isMounted) {
            let totalHours = parseInt(
              weekdays.data.total_hours + weekends.data.total_hours
            );
            let percentWeekDay = parseInt(
              (weekdays.data.total_hours * 100) / totalHours
            );
            let percentWeekEnd = parseInt(100 - percentWeekDay);

            self.setState({
              loadingWeeks: false,
              timeWeeks: [
                {
                  title: "Dias úteis",
                  total_hours: weekdays.data.total_hours,
                  percent: percentWeekDay
                },
                {
                  title: "Finais de semana",
                  total_hours: weekends.data.total_hours,
                  percent: percentWeekEnd
                }
              ]
            });
          }
        })
      );
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.lastfilter !== nextProps.lastfilter) {
      this.loadData();
    }
  }

  componentDidMount() {
    if (this.props.lastfilter) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // cancel request case when pending ...
    if (this.cancellation) {
      this.cancellation.cancel();
      this.cancellationWeekDay.cancel();
      this.cancellationWeekEnd.cancel();
      this._isMounted = false;
    }
  }

  onChangeIni = (time, timeString) => {
    if (timeString) {
      this.setState({ period_ini: timeString }, () => {
        this.loadData();
      });
    }
  };

  onChangeFin = (time, timeString) => {
    if (timeString) {
      this.setState({ period_fin: timeString }, () => {
        this.loadData();
      });
    }
  };

  render() {
    let options = {
      credits: { enabled: false },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        margin: [0, 0, 0, 0],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        height: "325"
      },
      title: this.state.title,
      tooltip: {
        pointFormat: " {point.percentage:.0f}% - {point.y} Salas",
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        borderWidth: 0,
        borderRadius: 5,
        shadow: false,
        useHTML: true,
        style: {
          padding: 0,
          color: "white"
        }
      },
      legend: {
        layout: "vertical",
        align: "left",
        verticalAlign: "bottom",
        floating: true
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false
          },
          showInLegend: true,
          size: "70%"
        }
      },
      series: this.state.series
    };

    return (
      <div>
        <Card
          style={{ minHeight: 445, width: "100%" }}
          title="Cenário de salas no período"
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={14}
            xxl={14}
            className="col-padding"
          >
            <Spin className="ant-spin-lg" spinning={this.state.loading}>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                oneToOne={true}
              />
            </Spin>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={10}
            xxl={10}
            className="col-padding"
          >
            <Card
              title="Tempo de ocupação de Salas"
              bordered={false}
              className="card-chart"
            >
              <Spin className="ant-spin-lg" spinning={this.state.loadingWeeks}>
                <List
                  itemLayout="horizontal"
                  dataSource={this.state.timeWeeks}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Progress
                            type="circle"
                            percent={item.percent}
                            width={80}
                          />
                        }
                        title={item.title}
                        description={<span>{item.total_hours} horas</span>}
                      />
                    </List.Item>
                  )}
                />
              </Spin>
            </Card>
          </Col>
        </Card>
      </div>
    );
  }
}

export default ChartOccupation;
